<template>
  <div class="header">
    <div class="bar">
      <div class="wrap">
        <img class="logo" src="@/assets/logo.png" alt="feysos">
        <div class="nav-wrap" :class="{ 'open': isOpen }">
          <button class="btn" @click="isOpen = true">
            <img src="@/assets/nav-btn.png" alt="打开导航">
          </button>
          <div class="mask" @click="isOpen = false" />
          <nav class="navs">
            <router-link v-for="(nav, index) in navs" :key="index" :class="{ 'active': currentActive(nav.path) }" :to="nav.path">{{ nav.title }}</router-link>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'

const navs = []
routes.forEach(item => {
  (item.meta || {}).navTitle && navs.push({
    title: item.meta.navTitle,
    path: item.path
  })
})

export default {
  name: 'MeHeader',
  data() {
    return {
      isOpen: false,
      navs
    }
  },
  watch: {
    '$route.path'() {
      this.isOpen = false
    }
  },
  methods: {
    currentActive(path) {
      return path === '/' ? this.$route.path === path : this.$route.path.search(path) === 0
    }
  }
}
</script>

<style lang="scss">
$size: $themeHeaderHeight;
.header-seat{
  box-sizing: border-box;
  padding-top: $size !important;
}
.header-offset{
  &.margin-top{
    margin-top: -$size !important;
  }
  &.margin-bottom{
    margin-top: $size !important;
  }
  &.top{
    top: -$size !important;
  }
  &.bottom{
    top: $size !important;
  }
}
.header{
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  z-index: 98;
  height: $size;
  .bar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $themeBarColor;
  }
  .wrap{
    display: flex;
    height: $size;
    justify-content: space-between;
    align-items: center;
  }
  .logo{
    display: block;
    width: 120px;
    height: 40px;
  }
  .btn{
    display: none;
  }
  .navs{
    display: flex;
    a{
      display: block;
      box-sizing: border-box;
      padding: 0 25px;
      line-height: $size;
      text-align: center;
      font-size: 18px;
      color: $themeColor;
      transition: transform .3s, color .3s;
      &.active, &:hover{
        transform: scale(1.1);
        color: $themeColorActive;
      }
    }
  }
  @media screen and (min-width: 800px){
    .navs a:last-child{
      padding-right: 0;
    }
  }
  @media screen and (max-width: 800px){
    .btn{
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      width: 50px;
      height: $size;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img{
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    .mask, .navs{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
    }
    .mask{
      left: 0;
      visibility: hidden;
      opacity: 0;
      background-color: $themeMaskColor;
      transition: opacity .3s;
    }
    .navs{
      width: 60%;
      flex-direction: column;
      background-color: $themeBarColor;
      transform: translateX(101%);
      transition: transform .3s;
      overflow-y: auto;
      overflow-x: hidden;
      a{
        width: 100%;
      }
    }
    .open{
      .mask{
        visibility: visible;
        opacity: 1;
      }
      .navs{
        transform: translateX(0%);
      }
    }
  }
}
</style>
