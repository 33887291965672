<template>
  <div id="app">
    <me-header />
    <transition name="slide-fade">
      <router-view class="me-container header-offset bottom" />
    </transition>
    <me-footer />
  </div>
</template>

<script>
import MeHeader from '@/components/meHeader'
import MeFooter from '@/components/meFooter'

export default {
  name: 'App',
  components: {
    MeHeader,
    MeFooter
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'panmenzhendao';
  src: url('./assets/panmenzhendao.ttf');
  // qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890
}
@font-face {
  font-family: 'gaoel';
  src: url('./assets/gaoel.ttf');
  // qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890
}
:root{
  @include scrollbar;
}
html{
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
body{
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: $themeBgColor;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ul{
  padding-left: 0;
  list-style: none;
}
p{
  margin-top: 0;
  margin-bottom: 0;
}
a{
  text-decoration: none;
  outline: 0 none;
  color: inherit;
}
input, textarea{
  @include placeholder;
}

#app{
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.me-container{
  flex-shrink: 1;
  flex-grow: 1;
  background-color: $themeBgColor;
  &.slide-fade-enter-active{
    & + .footer{
      opacity: 0;
    }
  }
  &.slide-fade-leave-active{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    & + .footer{
      opacity: 1;
    }
  }
}
.wrap {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 1220px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.btn{
  border: 0 none;
  background: transparent;
  outline: none;
  cursor: pointer;
  &.red, &.hyaline{
    border: 1px solid transparent;
    color: rgba(#fff, 1);
  }
  &.red{
    border-color: rgba(#ff0000, 1);
    background-color: rgba(#ff0000, 1);
  }
  &.hyaline{
    border-color: rgba(#fff, 1);
  }
  &.size-default{
    width: 150px;
    height: 50px;
    font-size: 20px;
    @media screen and (max-width: 400px) {
      &{
        width: 120px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
  &.change{
    transition: transform .3s;
    &:hover{
      transform: scale(1.05);
    }
    &:active{
      transition: none;
      transform: scale(1);
    }
  }
}
.img-scale{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  >img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.chunk-head{
  display: flex;
  padding-bottom: 90px;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  &:before{
    display: block;
    width: 5px;
    height: 30px;
    background-color: #ff0000;
    content: '';
  }
  &.enter{
    visibility: visible;
    animation: rollIn 1s ease 0s both;
  }
  &.leave{
    visibility: visible;
    animation: rollOut 1s ease 0s both;
  }
  div{
    line-height: 1em;
    &:nth-of-type(1) {
      margin-top: 25px;
      font-family: 'gaoel';
      text-transform: Uppercase;
      letter-spacing: 4px;
      font-size: 24px;
      color: $themeColor;
    }
    &:nth-of-type(2) {
      margin-top: 20px;
      letter-spacing: 1px;
      font-size: 32px;
      color: $themeColorActive;
    }
  }
  @media screen and (max-width: 800px) {
    padding-bottom: 50px;
    &:before{
      width: 3px;
      height: 20px;
    }
    div{
      line-height: 1em;
      &:nth-of-type(1) {
        letter-spacing: 3px;
        font-size: 14px;
      }
      &:nth-of-type(2) {
        font-size: 24px;
      }
    }
  }
}
.banner-container{
  &.index{
    height: 100vh;
  }
  &.other{
    height: 700px;
    .swiper{
      bottom: 100px;
    }
    @media screen and (max-width: 800px) {
      height: 0;
      padding-bottom: (700px - $themeHeaderHeight) / 800px * 100%;
      .swiper{
        bottom: 0;
      }
    }
  }
}
.banner-cover{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform-origin: center;
  &:before, &:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    content: '';
  }
  &:before{
    background: inherit;
    transform-origin: inherit;
    animation: banner-cover 20s linear 1s infinite alternate;
  }
  &:after{
    background: linear-gradient(rgba($themeBgColor, 0) 70%, rgba($themeBgColor, 1) 100%) no-repeat center bottom / 100% 100% rgba($themeBgColor, .2);
  }
  @keyframes banner-cover{
    from{
      transform: scale(1);
    }
    to{
      transform: scale(1.2);
    }
  }
}
.banner-desc{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(#fff, 1);
  .icon{
    position: relative;
    &:before, &:after{
      position: absolute;
      background-color: rgba(#ff0000, 1);
      @at-root .enter &{
        transition: transform 1s ease .3s;
      }
      @at-root .leave &{
        transition: transform 1s ease .4s;
      }
    }
    &:before{
      left: 0;
      width: 100%;
      transform: scale(0, 1);
      @at-root .enter &{
        transform: scale(1, 1);
      }
    }
    &:after{
      top: 0;
      height: 100%;
      transform: scale(1, 0);
      @at-root .enter &{
        transform: scale(1, 1);
      }
    }
  }
  .title{
    margin-top: 40px;
    line-height: 1em;
    opacity: 0;
    @at-root .enter &{
      animation: fadeInRight 1s ease .3s both;
    }
    @at-root .leave &{
      animation: fadeOut 1s ease .4s both;
    }
  }
  .text{
    min-height: 88px;
    margin-top: 30px;
    text-align: center;
    line-height: 44px;
    font-size: 22px;
    opacity: 0;
    @at-root .enter &{
      animation: fadeInUp 1s ease .4s both;
    }
    @at-root .leave &{
      animation: fadeOutDown 1s ease .2s both;
    }
  }
  &.index{
    .btn-wrap{
      margin-top: 60px;
      @at-root .enter &{
        animation: flipInX 1s ease .6s both;
      }
      @at-root .leave &{
        animation: fadeOutDownBig 1s ease 0s both;
      }
    }
    .btn + .btn{
      margin-left: 140px;
    }
    .icon{
      width: 50px;
      height: 50px;
      margin-top: -22px;
      &:before{
        top: 22px;
        height: 6px;
        content: '';
      }
    }
    .title{
      font-size: 80px;
    }
    @media screen and (max-height: 700px) {
      bottom: 80px;
    }
    @media screen and (max-width: 750px) {
      .title{
        font-size: 50px !important;
      }
      .text{
        font-size: 18px !important;
      }
      .btn + .btn{
        margin-left: 60px;
      }
    }
    @media screen and (max-width: 500px) {
      .title{
        font-size: 40px !important;
      }
      .text{
        font-size: 16px !important;
      }
      .btn + .btn{
        margin-left: 30px;
      }
    }
    @media screen and (max-width: 400px) {
      .title{
        font-size: 32px !important;
      }
      .text{
        font-size: 13px !important;
      }
    }
  }
  &.other{
    .icon{
      width: 30px;
      height: 30px;
      &:before, &:after{
        content: '';
      }
      &:before{
        top: 13px;
        height: 4px;
      }
      &:after{
        left: 13px;
        width: 4px;
      }
    }
    .title{
      font-size: 60px;
    }
    @media screen and (max-width: 900px) {
      .title{
        font-size: 50px !important;
      }
    }
    @media screen and (max-width: 750px) {
      .title{
        font-size: 32px !important;
      }
      .text{
        font-size: 18px !important;
      }
    }
    @media screen and (max-width: 500px) {
      bottom: 30px;
      .title{
        margin-top: 20px;
        font-size: 24px !important;
      }
      .text{
        min-height: 52px;
        margin-top: 15px;
        line-height: 26px;
        font-size: 16px !important;
      }
    }
    @media screen and (max-width: 400px) {
      .title{
        font-size: 20px !important;
      }
      .text{
        font-size: 13px !important;
      }
    }
  }
}
.english-run{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 42px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'panmenzhendao';
  line-height: 1em;
  font-size: 32px;
  color: rgba(#3c4351, .6);
  animation: englishRun 20s ease-in-out infinite alternate;
  @media screen and (max-height: 700px) {
    bottom: 18px;
  }
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
  @keyframes englishRun {
    0%, 2%{
      letter-spacing: 6vw;
      opacity: 0;
    }
    60%{
      opacity: 1;
    }
    90%, 100%{
      letter-spacing: 0vw;
      opacity: 1;
    }
  }
}
.introduction-list{
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  li{
    display: flex;
    transition: all .3s;
    visibility: hidden;
    &.enter{
      visibility: visible;
      animation: rotateInUpRight .6s ease backwards;
    }
    & + li{
      margin-top: 100px;
    }
    &:hover{
      background-color: rgba(#fff, .1);
      transform: translateY(-20px);
      box-shadow: 0 10px 20px 0 rgba(#000, .2);
      img{
        transform: scale(1.1);
      }
      .desc{
        transform: scale(.9);
      }
    }
  }
  .cover{
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    .img-scale{
      padding-top: 64%;
    }
    img{
      transition: all .3s;
    }
  }
  .desc{
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    display: flex;
    width: 100%;
    margin-left: 70px;
    flex-direction: column;
    font-size: 18px;
    transition: all .3s;
    &:after{
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      height: 50px;
      margin-top: 20px;
      border-top: 1px solid #ff0000;
      justify-content: center;
      align-items: flex-end;
      text-transform: Uppercase;
      line-height: 1em;
      font-family: 'gaoel';
      font-size: 16px;
      color: $themeColorIgnore;
      content: 'FEYSOS'
    }
  }
  .cn{
    line-height: 1.25em;
    font-size: 32px;
    color: $themeColorActive;
    @include text-overflow(2);
  }
  .en{
    margin-top: 30px;
    line-height: 1.25em;
    font-family: 'gaoel';
    text-transform: Uppercase;
    color: $themeColorStrong;
    @include text-overflow(2);
  }
  p{
    max-width: 100%;
    margin-top: 20px;
    line-height: 1.5em;
    text-align: justify;
    color: $themeColor;
    @include text-overflow(4);
  }
  @media screen and (max-width: 1000px) {
    li{
      & + li{
        margin-top: 50px;
      }
    }
    .cover{
      width: 40%;
    }
    .desc{
      font-size: 14px;
      &:after{
        height: 24px;
        font-size: 12px;
      }
    }
    .cn{
      font-size: 24px;
    }
    .en{
      margin-top: 20px;
    }
    p{
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 850px) {
    align-items: center;
    li{
      width: 80%;
      flex-direction: column;
    }
    .cover{
      width: 100%;
    }
    .desc{
      margin-left: 0;
      &:after{
        position: static;
      }
    }
    .cn{
      margin-top: 20px;
      text-align: center;
    }
    .en{
      text-align: center;
    }
  }
}

.slide-fade-enter, .slide-fade-leave-to{
  transform: translateY(100px);
  opacity: 0;
}
.slide-fade-enter-active, .slide-fade-leave-active{
  transition: all .5s ease;
}
.slide-fade-leave-active + .slide-fade-enter-active{
  transition-delay: .5s;
}
</style>
