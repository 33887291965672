<template>
  <div class="footer">
    <div class="wrap">
      <div class="contact">
        <div class="desc">
          <p class="title">费索斯</p>
          <p>地址：浙江省宁波高新区翔云北路 199 号 1 号楼 13-3</p>
        </div>
        <div class="code">
          <img src="@/assets/code.png" alt="二维码">
          <p>扫一扫了解更多</p>
        </div>
      </div>
      <div class="copyright">
        <span>版权所有@费索斯</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18004380号</a>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020902000450" target="_blank">浙公网安备33020902000450号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeFooter'
}
</script>

<style lang="scss" scoped>
a{
  &:hover{
    text-decoration: underline;
  }
}

.footer{
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  z-index: 0;
  background-color: $themeBarColor;
  color: $themeColor;
}
.contact{
  display: flex;
  padding: 80px 0 10px;
  justify-content: space-between;
  align-items: center;
  .desc{
    p{
      padding: 10px 0;
      line-height: 22px;
      font-size: 14px;
      &.title{
        font-size: 18px;
      }
    }
  }
  .code{
    img{
      display: block;
      width: 140px;
      height: 140px;
    }
    p{
      text-align: center;
      line-height: 50px;
      font-size: 18px;
    }
  }
}
.copyright{
  border-top: 1px solid rgba(#fff, .4);
  padding: 25px 0;
  line-height: 20px;
  font-size: 12px;
}
@media screen and (min-width: 750px){
  .contact{
    .desc{
      position: relative;
      padding-left: 30px;
      &:before{
        position: absolute;
        top: 13px;
        left: 0;
        bottom: 14px;
        width: 2px;
        background-color: rgba(#fff, 1);
        content: '';
      }
    }
  }
}
@media screen and (max-width: 750px){
  .contact{
    flex-direction: column;
    .desc{
      text-align: center;
    }
    .code{
      margin-top: 20px;
    }
  }
  .copyright{
    text-align: center;
  }
}
@media screen and (min-width: 500px){
  .copyright{
    a{
      margin-left: 2em;
    }
  }
}
@media screen and (max-width: 500px){
  .copyright{
    span, a{
      display: block;
    }
  }
}
</style>
