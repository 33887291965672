import 'animate.css'
import 'normalize.css'
import 'nprogress/nprogress.css'

import Vue from 'vue'
import NProgress from 'nprogress'
import App from '@/App.vue'
import router from '@/router'

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

Vue.component(swiper.name, swiper)
Vue.component(swiperSlide.name, swiperSlide)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
  NProgress.done()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
