import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let time = 0

export const routes = [
  {
    path: '/',
    name: 'index',
    meta: { navTitle: '首页' },
    component: () => import('@/views/index')
  },
  {
    path: '/apps',
    name: 'apps',
    meta: { navTitle: 'APP' },
    beforeEnter() { location.href = 'https://apps.feysos.com/?s=co' }
  },
  {
    path: '/miniapp',
    name: 'miniapp',
    meta: { navTitle: '小程序' },
    component: () => import('@/views/miniapp')
  },
  {
    path: '/advert',
    name: 'advert',
    meta: { navTitle: '广告投放' },
    component: () => import('@/views/advert')
  },
  {
    path: '/media',
    name: 'media',
    meta: { navTitle: '媒体变现' },
    component: () => import('@/views/media')
  },
  {
    path: '/about',
    name: 'about',
    meta: { navTitle: '关于我们' },
    component: () => import('@/views/about')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error')
  },
  {
    path: '*',
    redirect: '/error'
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    clearTimeout(time)
    return new Promise((resolve, reject) => {
      time = setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else if (to.hash) {
          resolve({ selector: to.hash })
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 500)
    })
  }
})
